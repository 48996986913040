import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Vegetace Evropy (významná společenstva)" />
    <h1>Vegetace Evropy (významná společenstva)</h1>



<p>Poč&aacute;tek v&yacute;zkumu vegetace Evropy a jej&iacute; členěn&iacute; do rostlinn&yacute;ch prostorov&yacute;ch <Link to="/biogeografie/spolecenstva/">společenstev</Link> se datuje do poč&aacute;tku 20.&nbsp;stolet&iacute;. Na tomto poč&aacute;tku stoj&iacute; Josias Braun-Blanquet (1884-1980), zakladatel fytocenologie, nauky o rostlinn&yacute;ch společenstvech.</p>
<p>N&iacute;že jsou pak vyps&aacute;na a pops&aacute;na vybran&aacute; společenstva evropsk&eacute;ho v&yacute;znamu a stručně shrnuta systematizace prostorov&yacute;ch společenstev na &uacute;zem&iacute; ČR.</p>
<hr />
<h2>Braun-Blanquetovsk&aacute; fytocenologie</h2>
<p>Braun-Blanquetovsk&aacute; fytocenologie je založena na zkoum&aacute;n&iacute; vegetace pomoc&iacute; fytocenologick&eacute;ho sn&iacute;mkov&aacute;n&iacute;. Toto sn&iacute;mkov&aacute;n&iacute; spoč&iacute;v&aacute; v z&aacute;pisu druhů a jejich pokryvnost&iacute; na vymezen&eacute; plo&scaron;e.</p>
<p>V&yacute;sledn&yacute; fytocenologick&yacute; sn&iacute;mek se pak sest&aacute;v&aacute; z pokryvnost&iacute; a seznamů druhů pro jednotliv&aacute; patra, kdy:</p>
<ul>
<li>E3 znač&iacute; stromov&eacute; patro</li>
<li>E2 keřov&eacute; patro</li>
<li>E1 bylinn&eacute; patro</li>
<li>E0 mechov&eacute; patro</li>
</ul>
<p>Pro určen&iacute; pokryvnosti se obvykle použ&iacute;v&aacute; semikvantitativn&iacute; <a href="https://www.priroda.cz/slovnik.php?detail=275">Braun-Blanquetova dev&iacute;tičlenn&aacute; stupnice</a>. Velikost fytocenologick&eacute;ho sn&iacute;mku se vol&iacute; dle typu společenstva, kdy v lesech jeho plocha čin&iacute; 100 až 400&nbsp;m2 a v travinn&eacute; vegetaci 1 až 25&nbsp;m2.</p>
<p>Fytocenologick&eacute; sn&iacute;mky jsou obvykle ukl&aacute;d&aacute;ny v n&aacute;rodn&iacute;ch (ČNFD) a mezin&aacute;rodn&iacute;ch (EVA) datab&aacute;z&iacute;ch. Vegetaci lze pak klasifikovat do typů na z&aacute;kladě druhov&eacute;ho složen&iacute;, což je jeden z pil&iacute;řů evropsk&eacute; ochrany př&iacute;rody. Na t&eacute;to b&aacute;zi byl i vytvořen hierarchick&yacute; syst&eacute;m vegetačn&iacute;ch typů použ&iacute;van&yacute; v Evropě:</p>
<ul>
<li><strong>tř&iacute;da </strong>(latinsky pojmenovan&eacute;, nejvy&scaron;&scaron;&iacute;, koncovka <em>-etea</em>)</li>
<li><strong>ř&aacute;d</strong> (<em>-etalia</em>)</li>
<li><strong>svaz</strong> (<em>-ion</em>)</li>
<li><strong>asociace</strong> (<em>-etum</em>)</li>
</ul>
<p>Seznam evropsk&yacute;ch vegetačn&iacute;ch jednotek lze naj&iacute;t na <a href="https://www.synbiosys.alterra.nl/evc/">EuroVegChecklist</a>.</p>
<hr />
<h2>Vegetačn&iacute; typy Evropy</h2>
<p>Mezi několik vybran&yacute;ch a n&iacute;že popsan&yacute;ch vegetačn&iacute;ch typů (společenstev) Evropy patř&iacute;:</p>
<ul>
<li><strong>mediter&aacute;nn&iacute; sklerofytn&iacute; lesy a křoviny</strong></li>
<li><strong>submediter&aacute;nn&iacute; lesy</strong></li>
<li><strong>mezofiln&iacute; opadav&eacute; listnat&eacute; a sm&iacute;&scaron;en&eacute; lesy temper&aacute;tn&iacute; Evropy</strong></li>
<li><strong>stepi a lesostepi v&yacute;chodn&iacute; Evropy</strong></li>
<li><strong>bore&aacute;ln&iacute; lesy severn&iacute; Evropy</strong></li>
<li><strong>vegetace arktick&eacute; tundry</strong></li>
</ul>
<h3>Mediter&aacute;nn&iacute; sklerofytn&iacute; lesy a křoviny</h3>
<p>Mediter&aacute;nn&iacute; sklerofytn&iacute; lesy a křoviny jsou typick&eacute; tepl&yacute;m a scuh&yacute;m l&eacute;tem, kdy převažuje v&yacute;par nad sr&aacute;žkami, a m&iacute;rnou zimou s ročn&iacute;m maximem sr&aacute;žek.</p>
<p>Vyskytuje se zde charakteristick&aacute; půda s označen&iacute;m Terra rossa, hlubok&aacute; j&iacute;lovit&aacute; půda v&aacute;pencov&yacute;ch zvětralin zbarven&aacute; Fe2O3. D&aacute;le se zde často vyskytuj&iacute; rendziny.</p>
<p>Jsou př&iacute;tomn&aacute; lesn&iacute; společenstva s dominanc&iacute; <strong>st&aacute;lezelen&yacute;ch tvrdolist&yacute;ch druhů dubů</strong> (dub okrouhlolist&yacute;, <em>Quercus rotundifolia</em>). Typick&yacute;m druhem je olivovn&iacute;k evropsk&aacute;, jehož roz&scaron;&iacute;řen&iacute; koresponduje s vymezen&iacute;m mediter&aacute;nn&iacute; oblasti. Čast&yacute;m druhem jsou i borovice, kter&eacute; obsazuj&iacute; zejm&eacute;na extr&eacute;mn&iacute; a naru&scaron;en&aacute; stanovi&scaron;tě. Př&iacute;tomn&eacute; jsou tak&eacute; lužn&iacute; lesy s topoly, platany nebo jasany.</p>
<p>M&aacute;lo světla v podrostu je př&iacute;činou realtivně mal&eacute;ho množstv&iacute; bylinn&yacute;ch druhů. Řada rostlin m&aacute; tak&eacute; li&aacute;novit&yacute; vzrůst. D&iacute;ky relativně čast&eacute; pastvě zv&iacute;řat se zde prosadily ostnat&eacute; druhy.</p>
<h4>Macchie</h4>
<p>Macchie jsou křovinn&aacute; vegetace mediter&aacute;nu, rozli&scaron;ujeme dva druhy:</p>
<ul>
<li><strong>prim&aacute;rn&iacute; macchie</strong> (přirozen&eacute; vždyzelen&eacute; křoviny nejteplej&scaron;&iacute;ch a nejsu&scaron;&scaron;&iacute;ch m&iacute;st)</li>
<li><strong>sekund&aacute;rn&iacute; macchie</strong> (tzv. <strong>matorral</strong>, hust&eacute; vysok&eacute; křoviny na stanovi&scaron;t&iacute;ch doubrav)</li>
</ul>
<h3>Submediter&aacute;nn&iacute; lesy</h3>
<p>Submediter&aacute;nn&iacute; lesy tvoř&iacute; přechod mezi společenstvy mediter&aacute;nn&iacute;ch a temper&aacute;tn&iacute;ch lesů Evropy. Jsou tvořeny <strong>opadav&yacute;mi</strong> dřevinami, předev&scaron;&iacute;m duby. V mediter&aacute;nu se tato vegetace vyskytuje v hor&aacute;ch, potom hovoř&iacute;me o supramediter&aacute;nn&iacute;m stupni.</p>
<p>Klimaticky se od mediter&aacute;nu li&scaron;&iacute; absenc&iacute; letn&iacute;ho aridn&iacute;ho obdob&iacute; a nev&yacute;raznost&iacute; zimn&iacute;ho sr&aacute;žkov&eacute;ho maxima.</p>
<h3>Mezofiln&iacute; opadav&eacute; listnat&eacute; a sm&iacute;&scaron;en&eacute; lesy temper&aacute;tn&iacute; Evropy</h3>
<p>Mezofiln&iacute; opadav&eacute; listnat&eacute; a sm&iacute;&scaron;en&eacute; lesy temper&aacute;tn&iacute; Evropy jsou nejběžněj&scaron;&iacute;m typem společenstev v Evropě. Děl&iacute; se na 2 z&aacute;kladn&iacute; oblasti:</p>
<ul>
<li><strong>atlantsk&aacute; oblast</strong></li>
<li><strong>kontinent&aacute;ln&iacute; oblast</strong></li>
</ul>
<h4>Atlantsk&aacute; oblast</h4>
<p>Atlantsk&aacute; oblast je charakterizovan&aacute; malou ročn&iacute; amplitudou teplot, zimy jsou bez mrazů. Sr&aacute;žky jsou trvale vysok&eacute; s m&iacute;rn&yacute;m poklesem v l&eacute;tě.</p>
<p>Převažuj&iacute; bučiny, doubravy a jaseniny. Vyskytuj&iacute; se zde i vždyzelen&eacute; rostliny.</p>
<h4>Kontinent&aacute;ln&iacute; oblast</h4>
<p>Kontinent&aacute;ln&iacute; oblast je typick&aacute; vy&scaron;&scaron;&iacute;mi rozd&iacute;ly letn&iacute;ch a zimn&iacute;ch teplot (př&iacute;tomnost mrazů). I sezonalita sr&aacute;žek je v&yacute;razněj&scaron;&iacute;, maxima dosahuj&iacute; sr&aacute;žkov&eacute; &uacute;hrny v letn&iacute;m obdob&iacute;. Typick&yacute; je v&yacute;skyt hněd&yacute;ch lesn&iacute;ch půd.</p>
<p>Dominuj&iacute; bučiny s jedl&iacute;, doubravy, dubohabřiny s př&iacute;měs&iacute; smrku, borov&eacute; a lipov&eacute; doubravy a bory. Buk ve v&yacute;chodn&iacute; Evropě pak chyb&iacute;.</p>
<h3>Stepi a lesostepi v&yacute;chodn&iacute; Evropy</h3>
<p>Nejdůležitěj&scaron;&iacute;m klimatick&yacute;m faktorem step&iacute; a lesostep&iacute; v&yacute;chodn&iacute; Evropy je sucho. Zat&iacute;mco ve step&iacute;ch se ročn&iacute; &uacute;hrn pohybuje od 350 do 450&nbsp;mm, v lesostep&iacute;ch od 400 do 550&nbsp;mm. Vedlej&scaron;&iacute;m faktorem jsou pak siln&eacute; mrazy n&iacute;zk&aacute; sněhov&aacute; pokr&yacute;vka (m&aacute;lo sr&aacute;žek a odv&iacute;v&aacute;n&iacute; siln&yacute;m větrem).</p>
<p>Půdy vznikly vět&scaron;inou na spra&scaron;&iacute;ch, ať už jde o černozemě, těžk&eacute; a jemnozrnn&eacute; půdy či tzv. ka&scaron;tanozemě vznikl&eacute; na těžk&yacute;ch spra&scaron;&iacute;ch v aridn&iacute;m klimatu. Společenstvo ovlivňuj&iacute; občasn&eacute; pož&aacute;ry.</p>
<h4>Stepn&iacute; vegetace</h4>
<p>Stepn&iacute; vegetace se nach&aacute;z&iacute; v su&scaron;&scaron;&iacute;ch oblastech a je tvořena společenstvy travin a bylin.</p>
<h4>Lesostepn&iacute; vegetace</h4>
<p>Lesostepn&iacute; vegetace se nach&aacute;z&iacute; ve vlhč&iacute;ch oblastech na přechodu mezi stepn&iacute; a lesn&iacute; vegetac&iacute;. Jde o jakousi mozaiku travino-bylinn&iacute; a lesn&iacute; vegetace.</p>
<p>Stepn&iacute; louky a lučn&iacute; stepi se stř&iacute;daj&iacute; s doubravami s dubem letn&iacute;k a &scaron;&iacute;p&aacute;kem, javory či jilmy, bory, sm&iacute;&scaron;en&yacute;mi borodoubravami či s březo-borov&yacute;mi lesy.</p>
<h3>Bore&aacute;ln&iacute; lesy severn&iacute; Evropy</h3>
<p>Klima bore&aacute;ln&iacute; lesů je charakterizov&aacute;no průměrnou ročn&iacute; teplotou -2 až 5&nbsp;&deg;C, přičemž směrem k v&yacute;chodu klesaj&iacute; a stoup&aacute; kontinentalita. Ročn&iacute; v nich spadne průměrně 450-550&nbsp;mm, na norsk&eacute;m pobřež&iacute; jsou v&scaron;ak &uacute;hrny vy&scaron;&scaron;&iacute;.</p>
<p>Nach&aacute;z&iacute; se zde vět&scaron;inou podzoly s kyselou reakc&iacute;. Permafrost v evropsk&eacute; č&aacute;sti bore&aacute;ln&iacute;ch lesů chyb&iacute;.</p>
<p>Jde povět&scaron;inou o jehličnat&eacute; nebo sm&iacute;&scaron;en&eacute; lesy s dominanc&iacute; smrku nebo borovice, v př&iacute;měsi se pak nach&aacute;z&iacute; nen&aacute;ročn&eacute; listn&aacute;če (např. bř&iacute;za, topol, osika). Na jihu bore&aacute;ln&iacute; z&oacute;ny jsou lesy sm&iacute;&scaron;en&eacute; s někter&yacute;mi temper&aacute;tn&iacute;mi druhy (dub, l&iacute;pa, javor, jilm). Př&iacute;tomnost ra&scaron;elini&scaron;ť.</p>
<h3>Vegetace arktick&eacute; tundry</h3>
<p>Vegetace arktick&eacute; tundry je travinn&aacute; a keř&iacute;čkovit&aacute;. Nach&aacute;z&iacute; se pouze v nejseverněj&scaron;&iacute;ch č&aacute;stech Evropy. Pol&aacute;rn&iacute; hranici lesa tvoř&iacute; ve Skandin&aacute;vii bř&iacute;za p&yacute;řit&aacute;, v evropsk&eacute; č&aacute;sti Ruska smrk sibiřsk&yacute; a na Uralu modř&iacute;n sibiřsk&yacute;. V hor&aacute;ch navazuje na alp&iacute;nskou vegetaci, zde je často horn&iacute; hranice lesa je&scaron;tě sn&iacute;žena pastvou sobů.</p>
<hr />
<h2>Vegetace ČR</h2>
<p>Naprostou vět&scaron;inu &uacute;zem&iacute; ČR pokr&yacute;v&aacute; mezofiln&iacute; opadav&yacute; listnat&yacute; a sm&iacute;&scaron;en&yacute; les temper&aacute;tn&iacute; Evropy. Pro podrobněj&scaron;&iacute; členěn&iacute; vegetace ČR do prostorov&yacute;ch společenstev se použ&iacute;v&aacute; několik způsobů, např&iacute;klad syst&eacute;m bioregionů, nejpouž&iacute;vaněj&scaron;&iacute;m jsou v&scaron;ak biotopy podle <a href="http://www.ochranaprirody.cz/res/Linkrchive/299/036740.pdf?seek=1465205752">Katalogu biotopů ČR</a>.</p>
<p>Jednotliv&eacute; biotopy maj&iacute; charakteristick&eacute; diagnostick&eacute; a dominantn&iacute; druhy, podle kter&yacute;ch b&yacute;vaj&iacute; vymezeny:</p>
<ul>
<li><strong>diagnostick&eacute; druhy</strong> (značka <strong>Dg</strong>, druhy vyskytuj&iacute;c&iacute; se hlavně v dan&eacute;m biotopu, zat&iacute;mco ve vět&scaron;ině jin&yacute;ch biotopů jsou vz&aacute;cn&eacute; nebo chyběj&iacute;)</li>
<li><strong>dominantn&iacute; druhy</strong> (značka <strong>Dm</strong>, druhy tvoř&iacute;c&iacute; podstatnou č&aacute;st biomasy jednotliv&yacute;ch porostn&iacute;ch pater)</li>
</ul>


    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Vegetace Evropy (významná společenstva)</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/spolecenstva/globalni-biomy/"><button className="inv">&larr; Biomy (globální společenstva)</button></Link>
    <Link to="/biogeografie/biodiverzita/"><button className="inv">Biodiverzita &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
